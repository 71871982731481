

.form-group {
    margin-bottom: 1rem;
}

label {
    margin-bottom: .5rem;
}

.site-title {
    display: contents;
}

.border-danger:focus{
    box-shadow: 0 0 0 0.25rem rgb(226 55 71 / 25%)!important;
}

.attachmentCard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.success-icon {
    fill: rgb(40,167,69);
}